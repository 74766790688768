/* eslint-disable camelcase */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { captureException } from "@sentry/browser";
import {
  getUserTokenFromStorage,
  removeItemFromStorage,
  setUserTokenInSessionStorage,
} from "./storage-variables";

type ErrorResponse = {
  [key: string]: string;
};

type ApiBrand = {
  name: string;
  parent_brand: string;
  created_at: string;
  updated_at: string;
};

const apiEndpoints = {
  // Account endpoints
  loginEndpoint: "/accounts/login/",
  registerEndpoint: "/accounts/register/",
  profileEndpoint: "/accounts/profile/",
  triggerPasswordResetEndpoint: "/accounts/send-reset-password-link/",
  resetPasswordEndpoint: "/accounts/reset-password/",
  setInitialPasswordAndActivateEndpoint: "accounts/set-initial-password/",
  verifyAndActivateEndpoint: "/accounts/verify-registration/",
  verifyAndUpdateEmailEndpoint: "/accounts/verify-update-email/",
  logoutEndpoint: "/accounts/logout/",
  // Other endpoints:
  favouriteShoeEndpoint: "/favourite-shoe/",
  measurementsEndpoint: "/manual-measurements/",
  usersEndpoint: "/users/",
  scansEndpoint: "/scans/",
  scanfilesEndpoint: "/scanfiles/",
  uploadOrders: "/orders/upload",
  brandsEndpoint: "/brands/",
  // Perfect ID endpoints:
  questionnairesEndpoint: "perfect-id/questionnaires/",
  swiperEndpoint: "perfect-id/swiper/",
  exportUserData: "/scans/export/",
};

// Type definitions
type User = {
  email: string;
  first_name: string;
  last_name: string;
  terms_and_conditions_accepted: boolean;
  newsletter_active: boolean;
  password: string;
  width: number;
  length: number;
  gender: string;
  is_partner_staff: boolean;
  is_customer: boolean;
  partner_can_manage_users: boolean;
  partner_can_manage_devices: boolean;
  partner_can_manage_orders: boolean;
  partner_can_manage_lasts: boolean;
  partner_can_use_commerce_plugin: boolean;
};

export type VerificationParams = {
  userId: string;
  timestamp: string;
  signature: string;
};

/**
 * REST API Service Class.
 */

class ApiService {
  private service: AxiosInstance;

  private exportService: AxiosInstance;

  public constructor() {
    const service = axios.create({
      baseURL:
        (window.env && window.env.ONEFID_CORE_API) ||
        "https://core.onefid.com/api/v2",
      timeout: 10000,
      headers: {
        ...(getUserTokenFromStorage() && {
          Authorization: `Token ${getUserTokenFromStorage()}`,
        }),
      },
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;

    const exportService = axios.create({
      baseURL:
        (window.env && window.env.ONEFID_CORE_API) ||
        "https://core.onefid.com/api/v2",
      timeout: 10000,
      headers: {
        ...(getUserTokenFromStorage() && {
          Authorization: `Token ${getUserTokenFromStorage()}`,
        }),
        Accept: "model/x.stl-binary",
      },
      responseType: "blob",
    });

    exportService.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
    this.exportService = exportService;
  }

  private handleSuccess = (response: AxiosResponse) => {
    return response;
  };

  private handleError = (error: AxiosError) => {
    const res = error.response;
    if (!res) {
      captureException(error);

      return Promise.reject(error);
    }
    const errorData: ErrorResponse = res.data;
    // TODO: Hack to treat text/html response as server error
    const contentType = res.headers["content-type"];
    const errorStatus =
      (contentType === "application/json" && res.status) || 500;

    // TODO: Check below code and see if the default case to server error makes sense.
    // See also: https://gist.github.com/paulsturgess/ebfae1d1ac1779f18487d3dee80d1258
    switch (errorStatus) {
      case 400: {
        const errorMessages: string[] = [];
        if (contentType === "application/json") {
          if (errorData.message) {
            errorMessages.push(errorData.message);
          } else {
            Object.values(errorData).map((err: any) => {
              if (err.message) {
                return errorMessages.push(err.message);
              }

              return err.map((e: any) => errorMessages.push(e.message));
            });
          }
        }
        if (errorMessages.length) return Promise.reject(errorMessages);
        captureException(error);
        break;
      }
      case 401:
        captureException(error);
        removeItemFromStorage("userToken");
        removeItemFromStorage("permissions");
        Promise.reject();
        break;
      case 403:
        captureException(error);
        removeItemFromStorage("userToken");
        removeItemFromStorage("userToken");
        Promise.reject();
        break;
      case 404:
        captureException(error);
        Promise.reject();
        break;
      default:
        captureException(error);
        Promise.reject(res.data);
    }

    return Promise.reject(errorData);
  };

  /**
   * User login request.
   *
   * @param email - Mail address of the user.
   * @param password - User password.
   */
  public fetchLogin = async (email: string, password: string) =>
    this.service
      .post(apiEndpoints.loginEndpoint, {
        email,
        password,
      })
      .then((response) => {
        if (response.status === 200) {
          setUserTokenInSessionStorage(response.data.token);
        }
      });

  /**
   * Upload orders.
   *
   * @param file - excel file containing orders.
   */
  public uploadOrders = async (file: string) => {
    const formData = new FormData();
    formData.append("file", file);
  
    return this.service
      .post(apiEndpoints.uploadOrders, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 60000, // Timeout set to 60 seconds (60000 milliseconds)
      })
      .then((response) => response.data)
      .catch((error: any) => error);
  };

  /**
   * Logs out the user.
   */
  public fetchLogout = () =>
    this.service
      .post(apiEndpoints.logoutEndpoint, {
        revoke_token: true,
      })
      .then(() => {
        removeItemFromStorage("userToken");
      });

  /**
   * Fetch user profile by his user token in the request header.
   */
  public fetchUserProfile = async () =>
    this.service
      .get(apiEndpoints.profileEndpoint)
      .then((response) => response.data);

  /**
   * Fetch user measurements by user id.
   *
   * @param email - User mail address.
   */
  public fetchUserMeasurements = (email: string) =>
    this.service
      .get(`${apiEndpoints.scansEndpoint}user/${email}/measurements/`)
      .then((response) => response.data);

  /**
   * Export users measurements to csv.
   *
   */
  public exportUsersMeasurements = () =>
    this.service
      .get(apiEndpoints.exportUserData, {
        headers: {
          "Accept": "text/csv",
        },
        timeout: 60000, // Timeout set to 60 seconds (60000 milliseconds)
      })
      .then((response) => response.data);

  /**
   * Fetch user image by image url.
   *
   * @param imageUrl - Static image url.
   */
  public fetchUserImage = (imageUrl: string) =>
    this.service
      .get(imageUrl, {
        responseType: "blob",
        headers: null,
      })
      .then((response) => response.data);

  /**
   * Fetch user manual measurements by user identifier.
   *
   * @param userId - User email address or employee number.
   */
  public fetchUserManualMeasurements = (userId: string) =>
    this.service
      .get(`${apiEndpoints.measurementsEndpoint}${userId}/`)
      .then((response) => response.data);

  /**
   * Fetch user favourite shoe by user identifier.
   *
   * @param userId - User email address or employee number.
   */
  public fetchUserFavouriteShoe = async (userId: string) =>
    this.service
      .get(`${apiEndpoints.favouriteShoeEndpoint}${userId}/`)
      .then((response) => response.data);

  /**
   * Fetch scan measurements by scan id.
   *
   * @param scanId - scan ID.
   */
  public fetchScanMeasurements = (scanId: number) =>
    this.service
      .get(`${apiEndpoints.scansEndpoint}${scanId}/measurements/`)
      .then((response) => response.data);

  /**
   * Fetch scan file by scan id.
   *
   * @param scanId - scan ID.
   */
  public fetchScanFile = (scanId: number) =>
    this.exportService
      .get(`${apiEndpoints.scanfilesEndpoint}${scanId}/mesh/`)
      .then((response) => response.data);

  /**
   * Register a new user.
   *
   * @param email - User email address.
   * @param first_name - User first name.
   * @param last_name - User last name.
   * @param terms_and_conditions_accepted - Agb.
   * @param newsletter_active - Newsletter accepted.
   * @param password - User password.
   * @param password_confirm - User password confirmation.
   */
  public postRegisterUser = async (
    email: string,
    first_name: string,
    last_name: string,
    terms_and_conditions_accepted: boolean,
    newsletter_active: boolean,
    password: string,
    password_confirm: string
  ) =>
    this.service.post(apiEndpoints.registerEndpoint, {
      email,
      first_name,
      last_name,
      terms_and_conditions_accepted,
      newsletter_active,
      password,
      password_confirm,
    });

  /**
   * Post call to activate the user when password was already set during registration.
   *
   * @param verificationParams - Object that contains userId, signature, and timestamp.
   */
  public postActivateUser = async (verificationParams: VerificationParams) => {
    const { userId, signature, timestamp } = verificationParams;

    return this.service.post(apiEndpoints.verifyAndActivateEndpoint, {
      user_id: userId,
      signature,
      timestamp,
    });
  };

  /**
   * Post call to reset the user's password.
   *
   * @param email - User mail address.
   * @param language - Save user preferred language (optional).
   */
  public postTriggerPasswordReset = async (email: string, language?: string) => {
    const headers = language ? { "Accept-Language": language } : {};
    
    return this.service.post(apiEndpoints.triggerPasswordResetEndpoint, {
      login: email,
      headers: headers,
    });
  };

  /**
   * Post call to set a new password.
   *
   * @param verificationParams - Unique identifier string for activation from backend.
   * @param verificationParams.userId - Part of the activation link.
   * @param verificationParams.signature - Part of the activation link.
   * @param verificationParams.timestamp - Part of the activation link.
   * @param password - User's new password.
   */
  public postResetPassword = async (
    { userId, signature, timestamp }: VerificationParams,
    password: string
  ) =>
    this.service.post(apiEndpoints.resetPasswordEndpoint, {
      user_id: userId,
      signature,
      timestamp,
      password,
    });

  /**
   * Post call to set initial password and activate the user.
   *
   * @param verificationParams - Unique identifier string for activation from backend.
   * @param verificationParams.userId - Part of the activation link.
   * @param verificationParams.signature - Part of the activation link.
   * @param verificationParams.timestamp - Part of the activation link.
   * @param password - User's initial password.
   */
  public postSetInitialPasswordAndActivate = async (
    { userId, signature, timestamp }: VerificationParams,
    password: string
  ) =>
    this.service.post(apiEndpoints.setInitialPasswordAndActivateEndpoint, {
      user_id: userId,
      signature,
      timestamp,
      password,
    });

  /**
   * Fetch perfect-id Questionnaires.
   */
  public fetchQuestionnaires = () =>
    this.service
      .get(`${apiEndpoints.questionnairesEndpoint}`)
      .then((response) => response.data);

  /**
   * Fetch perfect-id Swipers list.
   */
  public fetchSwipersList = () =>
    this.service
      .get(`${apiEndpoints.swiperEndpoint}`)
      .then((response) => response.data);

  /**
   * Returns the list of brands to display in the second favourite shoe step.
   */
  public fetchBrands = async (): Promise<string[]> =>
    this.service
      .get(apiEndpoints.brandsEndpoint, {
        params: { page_size: 5000 },
      })
      .then((response) =>
        response.data.results.map(
          (brand: ApiBrand) => brand.parent_brand === null && brand.name
        )
      );
}

export const getApiService = (): ApiService => {
  return new ApiService();
};
